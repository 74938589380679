import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { transparentize } from 'polished';

const IconChevron = ({ className, ...props }) => (
  <svg className={className} width="16px" viewBox="0 0 50 30" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1213.000000, -1582.000000)" fill="#ff0050" fillRule="nonzero">
        <polygon transform="translate(1238.000000, 1597.000000) scale(1, -1) translate(-1238.000000, -1597.000000) " points="1238 1582 1263 1612 1213 1612" />
      </g>
    </g>
  </svg>
);

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Chevron = styled(IconChevron)`
  transition: transform 200ms ease;
  ${({ open }) => open && css`transform: rotate(180deg);`}
`;

const Wrapper = styled.div`
  position: relative;
  width: calc(100vw - 40px);
  max-width: 456px;
  border-radius: 100px;
`;

const Button = styled.button`
  position: relative;
  background-color: rgba(255, 255, 255, 0.25);
  border: 3px solid #030436;
  border-radius: 8px;
  color: #030436;
  font-size: 1rem;
  font-weight: bold;
  height: 50px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
  z-index: 2;

  :focus {
    outline: 0;
  }

  :disabled {
    cursor: default;
  }
`;

const Label = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
  white-space: nowrap;
`;

const ChevronWrapper = styled.div`
  position: absolute;
  right: 15px;
  height: 24px;
  width: 42px;
  display: flex;
  justify-content: center;
`;

const ItemsContainer = styled.ul`
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;
  border: 3px solid #030436;
  border-radius: 0 0 8px 8px;
  border-top: 0;
  color: #030436;
  background-color: #f8f8f9;
  z-index: 1;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  animation: ${fadeIn} 200ms ease-out;
`;

const ItemContainer = styled.li`
  padding: 8px 15px;
  cursor: pointer;
  user-select: none;
  transition: background-color 150ms ease;

  ${({ disabled }) => disabled ? css`
    a, a:active, a:visited {
      color: ${transparentize(0.4, '#000')};
      text-decoration: none;
    }

    color: ${transparentize(0.5, '#000')};
    cursor: default;
  ` : css`
    :hover {
      background-color: ${transparentize(0.8, '#030436')};
    }
  `}
`;

class Dropdown extends Component {
  state = {
    open: false
  }

  handleClick = () => {
    this.setState(({ open }) => ({
      open: !open
    }));
  }

  handleItemClick = value => {
    this.setState({
      open: false
    });

    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  }

  render() {
    const { disabled, label, children, ...props } = this.props;
    const { open } = this.state;

    return (
      <Wrapper {...props}>
        <Button onClick={this.handleClick} disabled={disabled}>
          <Label>{label}</Label>
          <ChevronWrapper>
            {!disabled ? <Chevron open={open} /> : null}
          </ChevronWrapper>
        </Button>
        {open ? (
          <ItemsContainer>
            {React.Children.map(children, child => (
              child ? (
                React.cloneElement(child, {
                  onClick: this.handleItemClick
                })
              ) : null
            ))}
          </ItemsContainer>
        ) : null}
      </Wrapper>
    );
  }
}

const Item = React.memo(({ children, disabled, value, onClick }) => (
  <ItemContainer disabled={disabled} onClick={!disabled ? () => void onClick(value) : null}>{children}</ItemContainer>
));

Item.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.any.isRequired,
  onClick: PropTypes.func
};

Dropdown.Item = Item;

Dropdown.propTypes = {
  label: PropTypes.node.isRequired,
  onSelect: PropTypes.func
};

export default Dropdown;
