import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Title from '../components/Title';
import AccountingTable from '../components/AccountingTable';
import AccountingDetailBox from '../components/AccountingDetailBox';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 50px;
`;

const SubWrapper = styled.div`
  position: relative;
  margin-top: 50px;
  width: 100%;
  box-shadow: 0 0 16px #e3e3e3;
  padding-top: 20px;
  background-color: #fff;

  :before {
    content: '';
    z-index: -2;
    position: absolute;
    top: 50px;
    left: -40px;
    right: -40px;
    height: 100%;
    background-color: #0c2a7a;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  height: 240px;
`;

class AccountingDetailsContainer extends Component {
  static propTypes = {
    predictions: PropTypes.array,
    details: PropTypes.shape({
      invoiceName: PropTypes.string,
      invoiceDate: PropTypes.string,
      invoiceNumber: PropTypes.string,
      invoiceAmount: PropTypes.number,
      invoiceCurrency: PropTypes.string
    })
  }

  static defaultProps = {
    predictions: null,
    details: {}
  }

  render() {
    let invoiceAmount = this.props.details.invoiceAmount;
    if (invoiceAmount) {
      if (this.props.details.invoiceCurrency) {
        invoiceAmount = invoiceAmount.toLocaleString('en', {
          style: 'currency',
          currency: this.props.details.invoiceCurrency,
          currencyDisplay: 'symbol'
        });
      } else {
        invoiceAmount = invoiceAmount.toLocaleString('en', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    }

    return (
      <Wrapper>
        <Title id="accounting">{LANG.accountingPrediction.title}</Title>
        <SubWrapper>
          <BoxWrapper>
            <AccountingDetailBox color="#030436" title={LANG.accountingPrediction.documentName} value={this.props.details.invoiceName} />
            <AccountingDetailBox color="#ff0050" title={LANG.accountingPrediction.invoiceDate} value={this.props.details.invoiceDate} />
            <AccountingDetailBox color="#0c2a7a" title={LANG.accountingPrediction.invoiceNumber} value={this.props.details.invoiceNumber} />
            <AccountingDetailBox color="#4c69c5" title={LANG.accountingPrediction.totalAmount} value={invoiceAmount} />
          </BoxWrapper>
          <AccountingTable rows={this.props.predictions} />
        </SubWrapper>
      </Wrapper>
    );
  }
}

export default AccountingDetailsContainer;
