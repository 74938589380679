import React from 'react';
import styled from "styled-components";


const TooltipContent = styled.span`
  position: absolute;
  display: none; 
  box-shadow: 0px -1px 4px 0px #585656;
  background: rgb(51,58,68);
  color:#fff;
  padding:12px 14px;
  border-radius:4px;
  right: 0;
  top: -100%;
  white-space: nowrap;
  ::after{
    content:'';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #333a44;
    position: absolute;
    bottom: -5px;
  }
`;
const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  &:hover ${TooltipContent}{
    display: block;
  }
`;

export default function Tooltip({children}) {
  return <TooltipContainer>
    {children[0]}
    <TooltipContent>{children[1]}</TooltipContent>
  </TooltipContainer>;
}
