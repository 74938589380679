import React, { Component, Suspense } from 'react';
import axios from 'axios';

import NavBar from './components/NavBar';

import ExtractorPageContainer from './containers/ExtractorPageContainer';

import { readFileAsArrayBuffer } from './utils';

const UsageContainer = React.lazy(() => import('./containers/UsagePageContainer'));

class App extends Component {
  state = {
    config: null,
    license: null,
    licenseUpdating: false,
    showUsage: false
  }

  componentDidMount() {
    this.setup();
  }

  fetchConfig = async () => {
    const url = process.env.REACT_APP_EXTRACTOR_URL + '/config/v2';
    const res = await axios.get(url);

    return res.data;
  }

  fetchLicense = async () => {
    try {
      const url = process.env.REACT_APP_EXTRACTOR_URL + '/license';
      const res = await axios.get(url);

      return res.data;
    } catch (err) {
      console.error(`Failed to get license information: ${err}`);
    }
  }

  setup = async () => {
    const config = await this.fetchConfig();

    if (config.licenseEnabled) {
      const license = await this.fetchLicense();

      if (license) {
        this.setState({
          license
        });
      }
    }

    this.setState({
      config
    });
  }

  handleLicenseFileSelected = async file => {
    this.setState({
      licenseUpdating: true
    });

    try {
      const body = await readFileAsArrayBuffer(file);

      const url = process.env.REACT_APP_EXTRACTOR_URL + '/license';
      const res = await axios.post(url, body, {
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      });

      this.setState({
        license: res.data
      });
    } catch (err) {
      console.error(`Failed to update license information: ${err}`);
      alert('Failed to update license. Please try again!');
    }

    this.setState({
      licenseUpdating: false
    });
  }

  onShowUsageToggle = () => {
    this.setState({
      showUsage: !this.state.showUsage
    });
  }

  render() {
    return (
      <div>
        {this.state.license ? (
          <NavBar
            license={this.state.license}
            licenseUpdating={this.state.licenseUpdating}
            onLicenseFileSelected={this.handleLicenseFileSelected}
            onShowUsageToggle={this.onShowUsageToggle}
            showUsage={this.state.showUsage} />
        ) : null}

        <Suspense fallback={null}>
          {this.state.showUsage && this.state.license ? (
            <UsageContainer license={this.state.license} />
          ) : (
            <ExtractorPageContainer config={this.state.config} />
          )}
        </Suspense>
      </div>
    );
  }
}

export default App;
