import PropTypes from 'prop-types';

// Reference: https://gist.github.com/andjosh/6764939

// t = current time
// b = start value
// c = change in value
// d = duration
function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
}

function scrollToElem(toElemId, duration) {
  let to = 0;
  if (toElemId) {
    try {
      to = document.getElementById(toElemId).offsetTop;

      if (process.env.BUILD_TARGET === 'kirby') {
        const headers = document.getElementsByClassName('header');
        if (headers && headers.length > 0) {
          const header = document.getElementsByClassName('header')[0];
          to -= header.offsetHeight;
        }
      }

      const navBar = document.getElementById('navBar');
      if (navBar) {
        to -= navBar.offsetHeight;
      }
    } catch (err) {
      console.error(`Failed to get offsetTop of element #${toElemId}: ${err}`);
      return Promise.resolve();
    }
  }

  return Promise.all([
    scrollTo(document.documentElement, to, duration),
    scrollTo(document.body, to, duration)
  ]);
}

function scrollTo(element, to, duration, axis = 'top') {
  return new Promise(resolve => {
    const prop = 'scroll' + axis.slice(0, 1).toUpperCase() + axis.slice(1).toLowerCase();

    const start = element[prop];
    const change = (to - 10) - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element[prop] = val;
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        resolve();
      }
    };

    animateScroll();
  });
}

function isMobileDevice() {
  return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
}

function wait(timeout) {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
}

function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = err => reject(err);
    reader.readAsArrayBuffer(file);
  });
}

function base64ToBlobUrl(base64) {
  // Could be shortened to `const data = Uint8Array.from(atob(base64), c => c.charCodeAt(0));`
  // Sadly Uint8Array.from seems to be unsupported by Opera
  const binary = atob(base64);
  const data = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    data[i] = binary.charCodeAt(i);
  }
  const blob = new Blob([data], { type: 'image/png' });
  return URL.createObjectURL(blob);
}


// This is the list of properties which must be hidden in UI.
// TODO: Later to be moved to gateway
export const toHide = {
  'invoice':[],
  'receipt':[],
  'receipt/v2':[
    'general.totals.tax.net'
  ]
}

function transformPredictionData(data, extractionData) {
  const vendorAccount = getRandomInt(70000, 99999);

  const positionItems = extractionData.find(row => row.key === 'items.');
  if (!positionItems) {
    return [];
  }

  return data.map((item, i) => {
    if (item.account === 'UNK') {
      return null;
    }

    const positionItem = positionItems.value[i];

    // Fall back to positionTotalPrice, at the moment positionTotalPriceGross often ends up there instead
    // TODO: Remove as soon as post processing handles this right
    const totalPrice = (positionItem.find(row => row.key === 'items.positionTotalPriceGross') || positionItem.find(row => row.key === 'items.positionTotalPrice') || {value: null}).value;
    if (!totalPrice) {
      return null;
    }

    let taxRate = null;
    const tax = extractionData.find(row => row.key === 'general.totals.tax.');
    if (tax) {
      if (tax.value.length > 0) {
        taxRate = (tax.value[0].find(row => row.key === 'general.totals.tax.rate') || {value: null}).value;
      }
    }

    const postingKey = (() => {
      if (taxRate === 0.07) {
        return 8;
      } else if (taxRate === 0.19) {
        return 9;
      } else {
        return null;
      }
    })();

    const formattedTotalPrice = totalPrice.toLocaleString(process.env.BUILD_LANG, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    const currency = extractionData.find(row => row.key === 'general.currency') || {value: LANG.accountingPrediction.table.currency.unknown};
    return {
      name: (positionItem.find(row => row.key === 'items.name') || {value: null}).value,
      mainDebitCredit: totalPrice >= 0 ? LANG.accountingPrediction.table.credit : LANG.accountingPrediction.table.debit,
      totalPrice: formattedTotalPrice,
      currency: currency.value,
      vendorAccount,
      account: item.account,
      postingKey
    };
  }).filter(Boolean);
}

function getRandomInt(min, max) {
  return ~~(Math.random() * (max - min + 1) + min);
}

function groupExtractionData(modelName, data) {
  if (modelName === 'general') {
    return data.reduce((res, item) => {
      if (item.key.endsWith('.')) {
        const groupKey = item.key.slice(0, -1);
        if (!res[groupKey]) {
          res[groupKey] = [];
        }
        res[groupKey].push(item);
        return res;
      }

      res.general.push(item);
      return res;
    }, {
      general: []
    });
  }

  const groupedData = data.reduce((res, item) => {
    const identifier = item.key.substring(0, item.key.indexOf('.'));
    res[identifier].push(item);
    return res;
  }, {
    general: [],
    payment: [],
    items: [],
    contact: []
  });

  return groupedData;
}

function filterOutItems(itemList, rulesList){
  let amountDeleted  = 0;
  const filteredList =  rulesList.length ? itemList.reduce((finalList, item) => {
    if(rulesList.includes(item.key)){
      amountDeleted += 1;
      return finalList;
    }

    if(Array.isArray(item.value)){
      Object.assign(item, {
        value: item.value.map(sub => {
          const filteredSub = filterOutItems(sub, rulesList);
          amountDeleted += filteredSub.amountDeleted;
          return filteredSub.filteredList;
        })
      });
    }
    finalList.push(item);
    return finalList;
  }, []) : itemList;

  return {filteredList, amountDeleted}
}

export function filterExtractionData(extractionData, modelName){
  const filterResult = filterOutItems(extractionData.items, toHide[modelName] || []);
  return Object.assign({}, extractionData, {
    items: filterResult.filteredList,
    fullItemsCount: extractionData.fullItemsCount - filterResult.amountDeleted
  });
}
const KeyValuePropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  region: PropTypes.shape({
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    page: PropTypes.number
  })
});

function formatValue(value) {
  // format number values
  if (typeof value === 'number') {
    return value.toLocaleString(process.env.BUILD_LANG, {
      minimumFractionDigits: 2
    });
  }

  // format boolean values
  if (typeof value === 'boolean') {
    return value ? LANG.dataExtraction.table.value.yes : LANG.dataExtraction.table.value.no;
  }

  return value;
}

export {
  scrollToElem,
  scrollTo,
  isMobileDevice,
  wait,
  readFileAsArrayBuffer,
  base64ToBlobUrl,
  groupExtractionData,
  KeyValuePropType,
  transformPredictionData,
  getRandomInt,
  formatValue
};
