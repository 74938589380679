import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { formatValue, KeyValuePropType } from '../utils';

const TableWrapper = styled.div`
  box-sizing: initial; /* necessary for Kirby build target */
  display: flex;
  flex-direction: column;
  cursor: default;
  font-size: 0.95rem;
  height: 100%;
`;

const TableRow = styled.div`
  box-sizing: initial; /* necessary for Kirby build target */
  display: flex;
  border-bottom: 1px solid ${({ color }) => color};
  transition: background-color 80ms ease;

  :last-child {
    box-sizing: initial; /* necessary for Kirby build target */
    border-bottom: 0;
  }

  ${({ onMouseEnter, disableHover }) => (onMouseEnter && !disableHover) && css`:hover {
    transition: none;
    background-color: ${({ color }) => rgba(color, 0.4)};
  }`}
`;

const TableSubRow = styled(TableRow)`
  padding: 10px 0;
  padding-left: 8px;
`;

const HeaderRow = styled(TableRow)`
  color: ${({ color }) => color};
  transition: color 100ms ease;
  font-weight: bold;

  :hover {
    background-color: initial;
  }
`;

const TableColumn = styled.div`
  box-sizing: initial; /* necessary for Kirby build target */
  flex: ${({ flex = 1 }) => flex};
  word-break: break-word;

  ${({ unavailable }) => unavailable && css`
    opacity: 0.5;
  `}

  ${({ noPadding }) => !noPadding && css`
    padding: 10px 0;

    :nth-child(2) {
      box-sizing: initial; /* necessary for Kirby build target */
      padding-left: 8px;
    }
  `}
`;

const NumberColumn = styled(TableColumn)`
  display: flex;
  justify-content: center;
  flex: none;
  width: 40px;
  border-right: 1px solid ${({ color }) => color};
`;

const TableBody = styled.div`
  overflow-y: auto;
`;

class ExtractionTable extends Component {
  static propTypes = {
    modelName: PropTypes.string,
    rows: PropTypes.arrayOf(KeyValuePropType),
    onHover: PropTypes.func,
    onLeave: PropTypes.func,
    color: PropTypes.string
  }

  static defaultProps = {
    rows: [],
    color: '#53be79'
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.modelName !== nextProps.modelName ||
      this.props.rows !== nextProps.rows ||
      this.props.color !== nextProps.color ||
      this.props.onHover !== nextProps.onHover ||
      this.props.onLeave !== nextProps.onLeave
    );
  }

  getTableBody = ref => {
    this.tableBody = ref;
  }

  componentDidMount() {
    // See https://github.com/facebook/react/issues/14856
    this.tableBody.addEventListener('wheel', this.handleWheel);
  }

  componentWillUnmount() {
    this.tableBody.removeEventListener('wheel', this.handleWheel)
  }

  renderRows = (rows, i) => (
    <TableRow key={i} color={this.props.color}>
      <NumberColumn color={this.props.color}>{i + 1}</NumberColumn>
      <TableColumn flex="1" noPadding>
        {rows.map((row, j) => {
          const valueUnavailable = row.value === 'UNAUTHORIZED';
          const hoverDisabled = valueUnavailable || !row.region;
          return (
            <TableSubRow key={j} onMouseEnter={!hoverDisabled ? () => this.props.onHover(row) : () => this.props.onLeave()} color={this.props.color} disableHover={hoverDisabled}>
              <TableColumn flex="1" noPadding>{row.displayName || row.key}</TableColumn>
              <TableColumn flex="2" unavailable={valueUnavailable} noPadding>
                {valueUnavailable
                  ? LANG.dataExtraction.table.valueUnavailable
                  : formatValue(row.value)}
              </TableColumn>
            </TableSubRow>
          );
        })}
      </TableColumn>
    </TableRow>
  )

  renderRow = (row, i) => {
    const valueUnavailable = row.value === 'UNAUTHORIZED';
    const hoverDisabled = valueUnavailable || !row.region;
    return (
      <TableRow key={i} onMouseEnter={!hoverDisabled ? () => this.props.onHover(row) : () => this.props.onLeave()} color={this.props.color} disableHover={hoverDisabled}>
        <NumberColumn color={this.props.color}>{i + 1}</NumberColumn>
        <TableColumn flex="1">{row.displayName || row.key}</TableColumn>
        <TableColumn unavailable={valueUnavailable} flex="2">
          {valueUnavailable
            ? LANG.dataExtraction.table.valueUnavailable
            : formatValue(row.value)}
        </TableColumn>
      </TableRow>
    );
  }

  handleWheel = e => {
    // This prevents from scrolling the parent when reaching the start or end of the scroll container
    // Only the container itself should be scrolled as long as it receives wheel events
    if (e.currentTarget.scrollHeight > e.currentTarget.clientHeight) { // Check if container is scrollable
      e.preventDefault();
      e.currentTarget.scrollTop += e.deltaY;
    }
  }

  render() {
    let indexOffset = 0;

    return (
      <TableWrapper color={this.props.color}>
        <HeaderRow color={this.props.color}>
          <NumberColumn color={this.props.color}></NumberColumn>
          <TableColumn flex="1">{LANG.dataExtraction.table.fieldName}</TableColumn>
          <TableColumn flex="2">{LANG.dataExtraction.table.value}</TableColumn>
        </HeaderRow>
        <TableBody ref={this.getTableBody} onMouseLeave={this.props.onLeave}>
          {this.props.rows.map((row, i) => {
            // apply current offset
            i += indexOffset;
            // array rows end with an dot
            if (row.key.endsWith('.')) {
              // update next offset
              indexOffset += row.value.length - 1;
              // render rows
              return row.value.map((item, j) => {
                return this.renderRows(item, i + j)
              });
            } else {
              return this.renderRow(row, i);
            }
          })}
        </TableBody>
      </TableWrapper>
    );
  }
}

export default ExtractionTable;
