import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { scrollTo } from '../utils';

const Wrapper = styled.div`
  position: relative;
  /* 100vh - (82px header height + 35px table border + 70px buttons height + 10px top padding + 20px bottom padding) */
  max-height: calc(100vh - 252px);
  overflow-x: hidden;
  overflow-y: auto;
`;

const HighlighterContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const boxPadding = 5;
const HighlighterBox = styled.div.attrs(({ top, left, height, width, visible }) => ({
  style: {
    // Subtract one more pixel to correct offset position
    top: top - boxPadding / 2 - 1, left: left - boxPadding / 2 - 1,
    height: height + boxPadding, width: width + boxPadding,
    opacity: visible ? 1 : 0
  }
}))`
  box-sizing: initial; /* necessary for Kirby build target */
  position: absolute;
  border: 1px dashed ${({ color }) => color};
  background-color: ${({ color }) => rgba(color, 0.4)};
  ${process.env.BUILD_TARGET !== 'pi' && css`
    transition: all 150ms ease-out;
  `}
`;

class ImageHighlighter extends Component {
  static propTypes = {
    imageUrl: PropTypes.string.isRequired,
    highlightedRegion: PropTypes.shape({
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired
    }),
    highlightColor: PropTypes.string.isRequired
  }

  static defaultProps = {
    imageUrl: null,
    highlightedRegion: null
  };

  state = {
    imageHeight: null,
    imageWidth: null,
    latestRegion: null
  }

  componentDidMount() {
    // See https://github.com/facebook/react/issues/14856
    this.wrapper.addEventListener('wheel', this.handleWheel);
  }

  componentWillUnmount() {
    this.wrapper.removeEventListener('wheel', this.handleWheel);
  }

  imgRef = ref => {
    if (ref) { // Refs getting called with null on unmount
      ref.onload = () => {
        this.setState({
          imageHeight: ref.height,
          imageWidth: ref.width
        });
      }
    }
  }

  componentWillReceiveProps = nextProps => {
    // Reset cached image height and width on image change
    if (nextProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        imageHeight: null,
        imageWidth: null
      });
    }

    if (nextProps.highlightedRegion && this.wrapper && this.state.imageHeight) {
      this.setState({
        latestRegion: nextProps.highlightedRegion
      });

      if (!this.props.highlightedRegion || this.props.highlightedRegion.top !== nextProps.highlightedRegion.top) {
        let scrollBy = 0;

        if (nextProps.highlightedRegion.top * this.state.imageHeight + nextProps.highlightedRegion.height * this.state.imageHeight > this.wrapper.clientHeight + this.wrapper.scrollTop) {
          scrollBy = ((nextProps.highlightedRegion.top * this.state.imageHeight - boxPadding / 2 - 1) + (nextProps.highlightedRegion.height * this.state.imageHeight + boxPadding)) - (this.wrapper.clientHeight + this.wrapper.scrollTop) + 20;
        } else if (nextProps.highlightedRegion.top * this.state.imageHeight < this.wrapper.scrollTop) {
          scrollBy = (nextProps.highlightedRegion.top * this.state.imageHeight - boxPadding / 2 - 1) - this.wrapper.scrollTop - 20;
        }

        if (scrollBy !== 0) {
          scrollTo(this.wrapper, this.wrapper.scrollTop + scrollBy, 200);
        }
      }
    }
  }

  handleWheel = e => {
    // This prevents from scrolling the parent when reaching the start or end of the scroll container
    // Only the container itself should be scrolled as long as it receives wheel events
    if (e.currentTarget.scrollHeight > e.currentTarget.clientHeight) { // Check if container is scrollable
      e.preventDefault();
      e.currentTarget.scrollTop += e.deltaY;
    }
  }

  getWrapperRef = ref => {
    this.wrapper = ref;
  }

  render() {
    return (
      <Wrapper ref={this.getWrapperRef}>
        <img alt="Preview" src={this.props.imageUrl} style={{ display: 'block' }} width="470px" ref={this.imgRef} />
        <HighlighterContainer>
          {this.state.latestRegion && this.state.imageHeight && this.state.imageWidth
          ? <HighlighterBox
              top={this.state.imageHeight * this.state.latestRegion.top}
              left={this.state.imageWidth * this.state.latestRegion.left}
              height={this.state.imageHeight * this.state.latestRegion.height}
              width={this.state.imageWidth * this.state.latestRegion.width}
              color={this.props.highlightColor}
              visible={this.props.highlightedRegion} />
          : null}
        </HighlighterContainer>
      </Wrapper>
    );
  }
}

export default ImageHighlighter;
