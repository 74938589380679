import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { wait, scrollTo } from '../utils';

class Tab extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string
  };

  static defaultProps = {
    color: '#53be79'
  };

  render() {
    return this.props.children;
  }
}

const Container = styled.div`
  width: 548px;
`

const TabButton = styled.span`
  box-sizing: initial; /* necessary for Kirby build target */
  padding: 0 7px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #53be79;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  justify-content: center;
  cursor: pointer;
  transition: height 100ms ease-out;
`;

const TabNavButton = styled(TabButton)`
  flex: unset;
  width: 10px;
  background-color: #030436;
  user-select: none;
  ${({ position = 'left' }) =>
    position === 'left'
      ? css`
          margin-right: 4px;
        `
      : position === 'right'
      ? css`
          margin-left: 4px;
        `
      : null}

  :active {
    height: 35px;
  }
`;

const TabTitle = styled(TabButton)`
  flex: 1;
  margin-right: 4px;
  background-color: ${({ color }) => color || '#53be79'};
  ${({ active }) => active && 'height: 50px;'}

  :hover {
    height: 50px;
  }

  :active {
    height: 45px;
  }

  :last-child {
    margin-right: 0;
  }
`;

const TabTitlesOuterWrapper = styled.div`
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  align-items: flex-end;
`;

const TabTitlesWrapper = styled.div`
  flex: 1;
  display: inline-flex;
  position: relative;
  flex-direction: row;
  height: 50px;
  align-items: flex-end;
  white-space: nowrap;
  width: 100%;
  overflow-x: hidden;
`;

const TabContentWrapper = styled.div`
  overflow-y: hidden;
  border: ${({ color }) => `3px solid ${color || '#53be79'}`};
  transition: border 100ms ease;
`;

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
      if (propValue[key] && propValue[key].type !== Tab) {
        return new Error(`${componentName} child ${key} should be of type Tab.`);
      }
    }),
    selectedTab: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    selectedTab: 0
  };

  state = {
    needsScrolling: false
  };

  componentDidMount() {
    this.updateBodyHeight();
    this.checkNeedsScrolling();

    window.addEventListener('resize', this.updateBodyHeight);
    window.addEventListener('resize', this.checkNeedsScrolling);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTab !== this.props.selectedTab) {
      this.updateBodyHeight();
      this.checkNeedsScrolling();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateBodyHeight);
    window.removeEventListener('resize', this.checkNeedsScrolling);
  }

  updateBodyHeight = async () => {
    // Allow the content to expand
    this.innerBodyRef.style.height = 'auto';

    // Maximum height is calculated the following way:
    //   100vh - (82px header height + 50px tabs height + 3px table border + 70px buttons height + 10px top padding + 20px bottom padding)
    const targetHeight = Math.min(this.innerBodyRef.scrollHeight, window.innerHeight - 238);

    // Trigger transition if height changes
    if (this.bodyRef.clientHeight !== targetHeight) {
      this.bodyRef.style.transition = 'height 280ms cubic-bezier(0.22, 0.61, 0.36, 1)';
      this.bodyRef.style.pointerEvents = 'none';
      this.bodyRef.style.height = `${targetHeight}px`;
  
      // Wait for transition to finish
      await wait(300);

      this.bodyRef.style.transition = null;
      this.bodyRef.style.pointerEvents = null;
    }

    // Prevent content from expanding again (to allow scrolling inside of content)
    this.innerBodyRef.style.height = `${targetHeight}px`;
  }

  checkNeedsScrolling = () => {
    this.setState({
      needsScrolling: this.tabTitlesWrapperRef.scrollWidth > this.tabTitlesWrapperRef.clientWidth
    });
  }

  setBodyRef = ref => {
    this.bodyRef = ref;
  }

  setInnerBodyRef = ref => {
    this.innerBodyRef = ref;
  }

  setTabTitlesWrapperRef = ref => {
    this.tabTitlesWrapperRef = ref;
  }

  scrollLeft = () => {
    scrollTo(this.tabTitlesWrapperRef, this.tabTitlesWrapperRef.scrollLeft - 100, 200, 'left');
  }
 
  scrollRight = () => {
    scrollTo(this.tabTitlesWrapperRef, this.tabTitlesWrapperRef.scrollLeft + 100, 200, 'left');
  }

  render() {
    const tabs = React.Children.toArray(this.props.children);
    const selectedTabElement = tabs.find(tab => tab && tab.props.id === this.props.selectedTab);

    return (
      <Container>
        <TabTitlesOuterWrapper>
          {this.state.needsScrolling ? (
            <TabNavButton position="left" onClick={this.scrollLeft}>{'<'}</TabNavButton>
          ) : null}
          <TabTitlesWrapper ref={this.setTabTitlesWrapperRef}>
            {React.Children.map(tabs, (tab, i) => (
              tab ? (
                <TabTitle
                  key={i}
                  color={tab.props.color}
                  active={tab.props.id === this.props.selectedTab}
                  onClick={() => this.props.onChange(tab.props.id)}>
                  {tab.props.title}
                </TabTitle>
              ) : null
            ))}
          </TabTitlesWrapper>
          {this.state.needsScrolling ? (
            <TabNavButton position="right" onClick={this.scrollRight}>{'>'}</TabNavButton>
          ) : null}
        </TabTitlesOuterWrapper>
        <TabContentWrapper ref={this.setBodyRef} color={selectedTabElement.props.color}>
          <div ref={this.setInnerBodyRef}>
            {selectedTabElement}
          </div>
        </TabContentWrapper>
      </Container>
    );
  }
}
Tabs.Tab = Tab;

export { Tab };
export default Tabs;
