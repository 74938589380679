import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.95rem;
  width: 100%;
  max-height: 520px;
`;

const TableRow = styled.div`
  box-sizing: initial; /* necessary for Kirby build target */
  display: flex;
  padding: 10px 60px;
  border-bottom: 1px solid #88939e;
`;

const HeaderRow = styled(TableRow)`
  box-sizing: initial; /* necessary for Kirby build target */
  color: #030436;
  transition: color 100ms ease;
  font-weight: bold;
  cursor: default;
  border-color: #030436;
  border-bottom-width: 2px;
  padding: 10px 68px;
`;

const TableColumn = styled.div`
  box-sizing: initial; /* necessary for Kirby build target */
  flex: ${({ flex = 1 }) => flex};
  word-break: break-word;
  text-align: ${({ align = 'left' }) => align};
  padding: 0 10px;
`;

const ScrollWrapper = styled.div`
  box-sizing: initial; /* necessary for Kirby build target */
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
  padding-top: 0;
`;

const AccountingTable = ({ rows = [] }) => (
  <TableWrapper>
    <HeaderRow>
      <TableColumn flex="4">{LANG.accountingPrediction.table.bookingText}</TableColumn>
      <TableColumn flex="2">{LANG.accountingPrediction.table.credit}/{LANG.accountingPrediction.table.debit}</TableColumn>
      <TableColumn flex="2" align="right">{LANG.accountingPrediction.table.amount}</TableColumn>
      <TableColumn flex="2">{LANG.accountingPrediction.table.currency}</TableColumn>
      <TableColumn flex="2">{LANG.accountingPrediction.table.vendorAccount}</TableColumn>
      <TableColumn flex="2">{LANG.accountingPrediction.table.glAccount}</TableColumn>
      <TableColumn flex="2">{LANG.accountingPrediction.table.postingKey}</TableColumn>
    </HeaderRow>
    <ScrollWrapper>
      {rows.map((row, i) => (
        <TableRow key={i}>
          <TableColumn flex="4">{row.name || '—'}</TableColumn>
          <TableColumn flex="2">{row.mainDebitCredit || '—'}</TableColumn>
          <TableColumn flex="2" align="right">{row.totalPrice || '—'}</TableColumn>
          <TableColumn flex="2">{row.currency || '—'}</TableColumn>
          <TableColumn flex="2">{row.vendorAccount || '—'}</TableColumn>
          <TableColumn flex="2">{row.account || '—'}</TableColumn>
          <TableColumn flex="2">{row.postingKey || '—'}</TableColumn>
        </TableRow>
      ))}
    </ScrollWrapper>
  </TableWrapper>
);

export default React.memo(AccountingTable);
