import React from 'react';
import styled, { css } from 'styled-components';

import Logo from './Logo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  background-color: #ededef;
  ${({ withoutMargin }) => !withoutMargin && css`margin-top: 80px;`}
  padding-top: 32px;
  padding-bottom: 22px;
  box-sizing: border-box;
  ${process.env.BUILD_TARGET !== 'kirby' && css`box-shadow: inset 0 20px 30px -20px rgba(0, 0, 0, 0.2);`}
`;

const LogoLink = styled.a`
  opacity: 0.5;
  transition: opacity 150ms ease;

  :hover {
    opacity: 0.8;
  }
`;

const Link = styled.a`
  color: #151d24;
  opacity: 0.3;
  text-decoration: none;
  font-size: 0.8rem;
  transition: opacity 150ms ease;

  :hover {
    opacity: 0.8;
  }
`;

const Footer = process.env.BUILD_BRANDING === "smacc"
  ? ({ withoutMargin }) => (
    <Wrapper withoutMargin={withoutMargin}>
      <LogoLink href="https://smacc.io" target="_blank" rel="noopener noreferrer" style={{ height: "40px" }}>
        <Logo size="small" />
      </LogoLink>
      <Link href="https://smacc.io/imprint" target="_blank" rel="noopener noreferrer">Imprint</Link>
    </Wrapper>
  ) : ({ withoutMargin }) => (
    <Wrapper withoutMargin={withoutMargin}>
      <LogoLink href="https://hypatos.ai" target="_blank" rel="noopener noreferrer" style={{ height: "40px" }}>
        <Logo size="small" />
      </LogoLink>
      <Link href="https://hypatos.ai/imprint" target="_blank" rel="noopener noreferrer">Imprint</Link>
    </Wrapper>
  );

export default React.memo(Footer);
