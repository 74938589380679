import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';
import styled, { keyframes } from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import Header from '../components/Header';
import FileSelector from '../components/FileSelector';
import Dropdown from '../components/Dropdown';

import { scrollToElem, readFileAsArrayBuffer, wait } from '../utils';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const moveInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(80px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const moveInFromTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-22px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const AnimatedDropdown = styled(Dropdown)`
  z-index: 1;
  opacity: 0;
  animation: ${moveInFromBottom} 700ms 200ms cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
`;

const MergedFileSelector = styled(FileSelector)`
  position: absolute;
  top: 42px;
  opacity: 0;
  animation: ${moveInFromTop} 700ms 100ms cubic-bezier(0.22, 0.61, 0.36, 1) forwards;

  > button {
    border-top: 0;
    border-radius: 0 0 8px 8px;
    height: 55px;
    padding-top: 8px;
  }

  > input {
    top: 5px;
  }
`;

const Container = styled.div`
  /* 2.29rem = 0.7rem + 1.6em (based on 0.7rem); see below */
  min-height: ${process.env.BUILD_TARGET !== 'standalone' ? 'calc(110px + 2.29rem)' : '100px'};
`;

const FormContainer = styled.div`
  position: relative;
  height: 100px;
`;

const Label = styled.span`
  opacity: 0.5;
  font-size: 0.8rem;
  margin-left: 4px;
  font-weight: 500;
`;

const DisclaimerWrapper = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1000ms 500ms forwards;
  box-sizing: initial; /* necessary for Kirby build target */
  color: #fff;
  font-size: 0.7rem;
  line-height: 1.6em;
  width: 456px;
  box-sizing: border-box;
  padding: 0 6px;
  margin-top: 10px;
  text-align: justify;
  color: #030436;

  a {
    color: currentColor;
    text-decoration: none;
    /* necessary for Kirby build target */
    font-size: inherit;
    line-height: inherit;
  }
`;

const Disclaimer = styled.div`
  margin-top: 1em;
  overflow: hidden;
  transition: max-height 600ms cubic-bezier(0.22, 0.61, 0.36, 1);
  max-height: ${({ open }) => open ? '105px' : '0'};
`;

class HeaderContainer extends Component {
  static propTypes = {
    onExtract: PropTypes.func.isRequired,
    onStartExtract: PropTypes.func.isRequired,
    config: PropTypes.object
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.config) {
      return null;
    }

    if (
      props.config.availableModels !== state.availableModels ||
      (props.config.requestTimeout && props.config.requestTimeout !== state.requestTimeout) ||
      (props.config.invoiceCaEnabled && props.config.invoiceCaEnabled !== state.invoiceCaEnabled)
    ) {
      return {
        availableModels: props.config.availableModels,
        // If requestTimeout is not available set default of 2 minutes
        requestTimeout: props.config.requestTimeout || 120,
        model: !state.model ? (props.config.availableModels.length === 1 ? props.config.availableModels[0] : null) : state.model,
        invoiceCaEnabled: props.config.invoiceCaEnabled || false
      }
    }

    return null;
  }

  state = {
    extracting: false,
    errorMessage: null,
    disclaimerOpen: false,
    availableModels: [],
    requestTimeout: null,
    invoiceCaEnabled: false,
    model: null
  }

  currentCancel = null;
  recaptchaInstance = null;
  currentRecaptchaCallback = null;

  componentWillUnmount() {
    if (this.currentCancel) {
      this.currentCancel();
      this.currentCancel = null;
    }
  }

  handleRecaptchaChange = token => {
    if (this.currentRecaptchaCallback) {
      this.currentRecaptchaCallback(token);
    }
  }

  handleRecaptchaErrored = () => {
    if (this.currentRecaptchaCallback) {
      this.currentRecaptchaCallback(new Error('Verification errored'));
    }
  }

  waitForRecaptcha = () => {
    return new Promise((resolve, reject) => {
      // In case we already have a still valid token
      const token = this.recaptchaInstance.getValue();
      if (token) {
        return resolve(token);
      }

      this.currentRecaptchaCallback = res => {
        this.currentRecaptchaCallback = null;

        if (res === null) {
          return reject(new Error('Token invalidated'));
        }

        if (res instanceof Error) {
          return reject(res);
        }

        return resolve(res);
      };

      this.recaptchaInstance.execute();
    });
  }

  handleSubmit = async e => {
    const fileInput = e.target;
    if (this.state.extracting || fileInput.files.length === 0) return;

    const selectedFile = fileInput.files[0];

    this.setState({
      extracting: true,
      errorMessage: null,
      disclaimerOpen: false
    });

    scrollToElem(null, 300);
    // Wait to prevent lag in animation
    await wait(700);

    this.props.onStartExtract();

    try {
      let url = process.env.REACT_APP_EXTRACTOR_URL + '/extract?model=' + this.state.model.name;

      if (!process.env.BUILD_FLAGS['disable-recaptcha']) {
        // The if and else blocks have to stay in this order
        // for the bundler to be able to strip away our secret
        // token for BUILD_TARGET `standalone-public` and `kirby`
        if (process.env.BUILD_TARGET !== 'standalone-public' && process.env.BUILD_TARGET !== 'kirby') {
          url += '&rc_token=iqFLw76FIpEAWiP4QvsCmgKBh8CK78aP';
        } else {
          const token = await this.waitForRecaptcha();
          url += '&rc_token=' + token;
        }
      }

      const body = await readFileAsArrayBuffer(selectedFile);

      const dataExtractorResponse = await axios.post(url, body, {
        // Convert requestTimeout to milliseconds
        timeout: this.state.requestTimeout * 1000,
        headers: {
          'Content-Type': selectedFile.type
        },
        cancelToken: new CancelToken(c => {
          this.currentCancel = c;
        })
      });

      this.currentCancel = null;

      this.setState({
        extracting: false
      });

      this.props.onExtract(this.state.model, dataExtractorResponse.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      console.error(err);

      let message = LANG.upload.error.general;
      if (err.isAxiosError) {
        if (err.response && err.response.status === 400 && err.response.data.code === 'not_enough_words') {
          message = LANG.upload.error.empty;
        } else if (err.code === 'ECONNABORTED') {
          message = LANG.upload.error.network.timeout;
        } else if (err.response === undefined) {
          message = LANG.upload.error.empty;
        }
      }

      this.currentCancel = null;

      this.setState({
        extracting: false,
        errorMessage: message
      });

      document.addEventListener('mousemove', this.handleErrorMouseMove);
    } finally {
      fileInput.value = null;
    }
  }


  handleErrorMouseMove = () => {
    setTimeout(() => {
      this.setState({
        errorMessage: null
      });
      document.removeEventListener('mousemove', this.handleErrorMouseMove);
    }, 3000);
  }

  toggleDisclaimer = e => {
    e.preventDefault();
    if (this.state.extracting) return;

    this.setState(prevState => ({ disclaimerOpen: !prevState.disclaimerOpen }));
  }

  handleModelChange = model => {
    if (this.state.model) {
      this.setState({
        model: null
      }, () => {
        this.setState({
          model
        });
      });
    } else {
      this.setState({
        model
      });
    }
  }

  setRepatchaInstance = instance => {
    this.recaptchaInstance = instance;
  }

  render() {
    return (
      <Header>
        <Container>
          <FormContainer>
            {this.state.availableModels.length > 0 ? (
              <AnimatedDropdown
                label={
                  this.state.model ? (
                    <>
                      {this.state.model.displayName || this.state.model.name}
                      <Label>({this.state.model.version || 'latest'})</Label>
                    </>
                  ) : LANG.upload.model.select
                }
                disabled={this.state.extracting || this.state.availableModels.length === 1}
                onSelect={this.handleModelChange}
              >
                {this.state.availableModels.map(model => (
                  <Dropdown.Item value={model} key={model.name}>
                    {model.displayName || model.name}
                    <Label>({model.version || 'latest'})</Label>
                  </Dropdown.Item>
                ))}
                {process.env.BUILD_TARGET !== 'standalone' ? (
                  <Dropdown.Item value="more" disabled>
                    Contact <a href="mailto:sales@hypatos.ai">sales@hypatos.ai</a> for information on other models.
                  </Dropdown.Item>
                ) : null}
              </AnimatedDropdown>
            ) : null}
            {this.state.model ? (
              <MergedFileSelector
              style={{ animationDelay: this.state.availableModels.length === 1 ? "600ms" : null }}
              label={
                this.state.errorMessage || (
                  <>
                    {LANG.upload.prompt}
                    <Label>(PDF, JPG, PNG, TIFF)</Label>
                  </>
                )
              } onChange={this.handleSubmit} variant={this.state.errorMessage ? "error" : "normal"} processing={this.state.extracting} />
            ) : null}
            {(process.env.BUILD_TARGET === 'standalone-public' || process.env.BUILD_TARGET === 'kirby') && !process.env.BUILD_FLAGS['disable-recaptcha'] ? (
              <ReCAPTCHA
                ref={this.setRepatchaInstance}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                size="invisible"
                onChange={this.handleRecaptchaChange}
                onErrored={this.handleRecaptchaErrored}
              />
            ) : null}
          </FormContainer>
            {process.env.BUILD_TARGET !== 'standalone' && this.state.model ? (
              <DisclaimerWrapper>
                <a href="#terms-and-conditions" onClick={this.toggleDisclaimer}>{LANG.upload.disclaimer.title}</a>
                <Disclaimer open={this.state.disclaimerOpen}>{LANG.upload.disclaimer}</Disclaimer>
              </DisclaimerWrapper>
            ) : null}
        </Container>
      </Header>
    );
  }
}

export default HeaderContainer;
