import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Title from '../components/Title';
import Tooltip from '../components/Tooltip';
import fileIconFail from '../images/icon-fail.svg';
import fileIconSuccess from '../images/icon-success.svg';
import fileIconInfo from '../images/icon-info.svg';

const Section = styled.div`
  margin-top:60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CenteredTitle = styled(Title)`
  text-align: center;
`;

const List = styled.ul`
  background: #0c2a7a;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  box-shadow: inset -1px 0px 1px 2px #082061;
  margin-top: 60px;

`;

const Item = styled.li`
  width:50%;
  padding:10px 20.5px;
  box-sizing: border-box;
`;

const ItemContent = styled.div`
  padding: 23px 25px 23px 30px;
  background-color:#fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: -1px 0 1px 2px #082061;
`;

const ItemLabel = styled.span`
  flex-basis: 100%;
  padding-left:35px;
`

const IconFail = () => <img src={fileIconFail} alt="failed"/>
const IconSuccess = () => <img src={fileIconSuccess} alt="success"/>
const IconInfo = () => <img src={fileIconInfo} alt="info"/>


export default class VatComplianceContainer extends PureComponent {
  static checklistSemantics = [
    {key: 'sender_add'},
    {key: 'recipient_add'},
    {key: 'tax_id'},
    {key: 'invoice_date'},
    {key: 'invoice_number'},
    {key: 'item_name'},
    {key: 'service_time'},
    {key: 'net'},
    {key: 'tax'},
    {key: 'tax_zero'},
  ];

  static propTypes = {
    rules: PropTypes.shape(VatComplianceContainer.checklistSemantics.reduce(
      (shape, {key}) => ({
          ...shape,
          [key]:PropTypes.bool.isRequired
      }), {})).isRequired
  };

  render() {
    return <Section id="vat-compliance">
      <CenteredTitle>{LANG.vatCompliance.title}</CenteredTitle>
      <List>
        {VatComplianceContainer.checklistSemantics.map(({key}) => <Item key={key}>
          <ItemContent>
            {this.props.rules[key] ? <IconSuccess/> : <IconFail/>}
            <ItemLabel>{LANG.vatCompliance.items[key].label || key}</ItemLabel>
            <Tooltip>
              <IconInfo/>
              <span>{LANG.vatCompliance.items[key].description[ this.props.rules[key] ? 'passed' : 'failed' ]}</span>
            </Tooltip>
          </ItemContent>
        </Item>)}
      </List>
    </Section>
  }
}

