import React from "react";

import iconHypatos from "../images/icon-hypatos.svg";
import iconSMACC from "../images/icon-smacc.svg";

const sizes = {
  small: 40,
  normal: 58
};

const Logo = process.env.BUILD_BRANDING === "smacc"
  ? ({ size = "normal" }) => (
    <img alt="SMACC" src={iconSMACC} height={sizes[size] + "px"} />
  ) : ({ size = "normal" }) => (
    <img alt="Hypatos" src={iconHypatos} height={sizes[size] + "px"} />
  );

export default React.memo(Logo);
