import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import HeaderContainer from './HeaderContainer';
import ExtractionDetailsContainer from './ExtractionDetailsContainer';
import AccountingDetailsContainer from './AccountingDetailsContainer';
import VatComplianceContainer from './VatComplianceContainer';
import Footer from '../components/Footer';
import Logos from '../components/Logos';

import {
  groupExtractionData,
  transformPredictionData,
  scrollToElem,
  base64ToBlobUrl,
  filterExtractionData
} from '../utils';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 20px 45px 20px;
`;

const buildDetailsObject = extractionData => {
  let invoiceName = (extractionData.find(row => row.key === 'contact.sender.companyName') || extractionData.find(row => row.key === 'general.type') || {value: ''}).value.replace(/\s/g, '_');
  const number = extractionData.find(row => row.ket === 'general.number');
  if (number) {
    invoiceName += number.value.replace(/\s/g, '_');
  }

  return {
    invoiceName,
    invoiceDate: (extractionData.find(row => row.key === 'general.issuedAt') || {value: null}).value,
    invoiceNumber: (extractionData.find(row => row.key === 'general.number') || {value: null}).value,
    invoiceAmount: (extractionData.find(row => row.key === 'general.totals.gross') || {value: null}).value,
    invoiceCurrency: (extractionData.find(row => row.key === 'general.currency') || {value: null}).value
  };
}

class ExtractorPageContainer extends Component {
  static propTypes = {
    config: PropTypes.object
  }
  state = {
    images: null,
    model: null,
    results: null,
    predictions: null,
    resultsCount: 0,
    predictionsCount: 0,
    details: null
  }

  handleReset = () => {
    if (this.state.images) {
      // clean up object URLs for previews
      for (const imageUrl of this.state.images) {
        URL.revokeObjectURL(imageUrl);
      }
    }

    this.setState({
      images: null,
      model: null,
      results: null,
      predictions: null,
      resultsCount: 0,
      predictionsCount: 0,
      details: null
    });
  }

  handleExtraction = (model, extractionData) => {
    const filteredExtractionData = filterExtractionData(extractionData, model.name)
    const groupedExtractionData = groupExtractionData(model.name, filteredExtractionData.items);
    const transformedPredictionData = transformPredictionData(filteredExtractionData.caPredictions || [], filteredExtractionData.items);

    this.setState({
      images: filteredExtractionData.previews.map(base64ToBlobUrl),
      model,
      results: groupedExtractionData,
      resultsCount: filteredExtractionData.fullItemsCount,
      predictions: transformedPredictionData,
      predictionsCount: transformedPredictionData.length,
      details: buildDetailsObject(filteredExtractionData.items),
      vatValidationResult:filteredExtractionData.vatValidationResult
    });
    setTimeout(() => scrollToElem('extraction', 700), 600);
  }

  getTaxAmount = () => this.state.vatValidationResult ? VatComplianceContainer.checklistSemantics.filter(
    ({key}) => !this.state.vatValidationResult[key]
  ).length : -1;

  renderContent = () => (
    <ContentWrapper>
      <ExtractionDetailsContainer taxRisksAmount={this.getTaxAmount()} imageUrls={this.state.images} model={this.state.model} results={this.state.results} resultsCount={this.state.resultsCount} predictionsCount={this.state.predictionsCount} />
      {!!this.state.vatValidationResult &&  <VatComplianceContainer rules={this.state.vatValidationResult} />}
      {this.state.predictionsCount > 0 ? <AccountingDetailsContainer predictions={this.state.predictions} details={this.state.details} /> : null}
    </ContentWrapper>
  );

  renderFooter = () => process.env.BUILD_TARGET !== 'kirby' ? <Footer withoutMargin={this.state.showUsage} /> : null

  render() {
    return (
      <>
        <HeaderContainer
          onExtract={this.handleExtraction}
          onStartExtract={this.handleReset}
          config={this.props.config}
        />
        <Logos />
        {this.state.images ? this.renderContent() : null}
        {this.state.images ? this.renderFooter() : null}
      </>
    );
  }
}

export default ExtractorPageContainer;
