import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import iconChip from '../images/icon-chip.svg';

const pulse = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: calc(100vw - 40px);
  max-width: 456px;
  border-radius: 100px;
`;

const Button = styled.button`
  position: relative;
  background-color: rgba(255, 255, 255, 0.25);
  border: 3px solid #030436;
  border-radius: 8px;
  color: #030436;
  font-size: 1rem;
  font-weight: bold;
  height: 50px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  text-align: left;

  :focus {
    outline: 0;
  }
`;

const Chip = styled.img.attrs(() => ({
  src: iconChip
}))`
  height: 23px;

  ${({ pulsing }) => pulsing && css`
    animation: ${pulse} 1.2s ease-in infinite;
  `}
`;

// To prevent getting the hidden prop applied to the span
const LabelSpan = ({ variant, hidden, ...props }) => <span {...props} />
const Label = styled(LabelSpan)`
  /* In general an error message shouldn't take more than 2 lines. */
  /* We could clamp after 2 lines but this requires using deprecated CSS features. See below. */
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-right: 45px;
  transition: opacity 200ms ${({ hidden }) => hidden ? '0' : '1000'}ms ease;
  opacity: ${({ hidden }) => hidden ? 0 : 1};
  ${({ variant }) => variant === 'error' && css`
    color: #fd5b11;
    animation: ${pulse} 1.2s 1400ms ease-in infinite;
  `}
`;

const Input = styled.input.attrs(() => ({
  type: 'file',
  accept: 'application/pdf,image/png,image/jpeg,image/tiff'
}))`
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
`;

const ChipWrapper = styled.div`
  position: absolute;
  left: 15px;
  right: 15px;
  height: 24px;
  text-align: right;

  transform: translateX(0px);
  transition: transform 450ms ${({ centered }) => centered ? 100 : 0}ms ease;

  ${({ centered }) => centered && css`
    /* Move by half width of this wrapper minus the half of the width of the icon */
    transform: translateX(calc(-50% + 46px / 2));
  `}
`;

const FileSelector = ({ label, processing, variant = 'normal', disabled = false, onChange, ...props }) => (
  <Wrapper {...props}>
    <Button>
      <Label hidden={processing} variant={variant}>{label}</Label>
      <ChipWrapper centered={processing}>
        <Chip pulsing={processing} />
      </ChipWrapper>
    </Button>
    {!processing && !disabled ? <Input onChange={onChange} /> : null}
  </Wrapper>
);

FileSelector.propTypes = {
  label: PropTypes.node.isRequired,
  processing: PropTypes.bool,
  onChange: PropTypes.func
};

export default React.memo(FileSelector);
