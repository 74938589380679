import React from 'react';
import styled, { css } from 'styled-components';

import Logo from '../components/Logo';
import Particles from '../components/Particles';

import headerHero from '../images/header-hero.svg';

const Container = styled.header`
  position: relative;
  height: calc(100vh - 201px);
  width: 100%;
  background-color: rgba(237, 237, 239, 0.4);
  ${process.env.BUILD_TARGET !== 'kirby' && css`box-shadow: inset 0 -20px 30px -20px rgba(0, 0, 0, 0.2);`}
`;

const LogoContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 120px 20px 0 20px;
`;

const ContentContainer = styled.div`
  box-sizing: initial; /* necessary for Kirby build target */
  position: relative; /* Necessary for z-index of hero background to work */
  height: ${process.env.BUILD_TARGET !== 'kirby' ? 'calc(100% - 140px)' : '100%'};
  display: flex;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
  padding: 0 20px;
  ${process.env.BUILD_TARGET === 'kirby' && css`padding-top: 58px;`}
`;

const HeroContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 80%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-top: 108px;
  width: 960px;
  overflow: hidden;

  > img {
    display: block;
    height: 714px;
    width: 960px;
  }

  @media (min-width: 1600px) {
    width: 1210px;

    > img {
      display: block;
      height: 900px;
      width: 1210px;
    }
  }
`;

const Header = ({ children }) => (
  <Container>
    {process.env.BUILD_TARGET !== 'kirby' ? (
      <LogoContainer>
        <Logo />
      </LogoContainer>
    ) : null}
    <ContentContainer>
      {children}
    </ContentContainer>
    {process.env.NODE_ENV === 'production' && process.env.BUILD_TARGET !== 'pi' ? <Particles /> : null}
    <HeroContainer>
      <img alt="" src={headerHero} />
    </HeroContainer>
  </Container>
);

export default React.memo(Header);
