import React from 'react';
import styled, { css } from 'styled-components';
import iconHypatos from '../images/icon-hypatos.svg';

const Container = styled.div.attrs({
  id: 'navBar' // This is used by scrollToElem from utils.js
})`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 0 8px #B0B1B2;
  overflow: hidden;
`;

const Content = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px 20px;
  box-sizing: initial;
  display: flex;
  height: 40px;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
`;

const UsageButton = styled.button`
  position: relative;
  margin: 0 8px;
  padding: 8px 15px;
  border: 1px solid #0c2a7a;
  background-color: transparent;
  color: #0c2a7a;
  border-radius: 3px;
  font-size: 0.8rem;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  transition: background-color 150ms ease, color 150ms ease;

  :hover {
    background-color: #0c2a7a;
    color: white;
  }

  :after {
    content: '';
    position: absolute;
    top: 100%;
    left: -1px;
    right: -1px;
    background-color: #0c2a7a;
    height: 0px;
    transition: height 150ms ease-out;
  }

  ${({ active }) => active && css`
    background-color: #0c2a7a;
    color: white;
    border-radius: 3px 3px 0 0;

    :after {
      height: 15px;
    }
  `}

  :focus {
    outline: 0;
  }
`;

const UpdateButton = styled.button`
  position: relative;
  margin: 0 8px;
  padding: 8px 15px;
  border: 1px solid #ff0050;
  background-color: transparent;
  color: #ff0050;
  border-radius: 3px;
  font-size: 0.8rem;
  text-decoration: none;
  display: inline-block;
  transition: background-color 150ms ease, color 150ms ease;
  cursor: pointer;
  
  :not(:disabled):hover {
    background-color: #ff0050;
    color: white;
  }

  :disabled {
    cursor: default;
  }

  :focus {
    outline: 0;
  }
`;

const LogoButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;

  :focus {
    outline: 0;
  }
`;

const Input = styled.input.attrs(() => ({
  type: 'file'
}))`
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
`;

const NavBar = ({ license, licenseUpdating, onLicenseFileSelected, onShowUsageToggle, showUsage }) => {
  const canExpire = !!license.expiration_time;

  return (
    <Container>
      <Content>
        <LogoButton onClick={showUsage ? onShowUsageToggle : null}>
          <img alt="Hypatos" src={iconHypatos} height="18px" />
        </LogoButton>
        <span>
          <UsageButton active={showUsage} onClick={onShowUsageToggle}>Usage Report</UsageButton>
          {canExpire ? (
            <UpdateButton disabled={licenseUpdating}>
              {licenseUpdating ? 'Updating license...' : 'Update license'}
              {!licenseUpdating ? (
                <Input onChange={e => {
                  const fileInput = e.target;
                  if (fileInput.files.length === 0) return;
                  onLicenseFileSelected(fileInput.files[0]);
                  fileInput.value = null;
                }} />
              ) : null}
            </UpdateButton>
          ) : null}
        </span>
      </Content>
    </Container>
  );
}

export default NavBar;
