import React from 'react';
import styled, { css } from 'styled-components';
import CountUp from 'react-countup';
import { transparentize } from 'polished';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};

  ${({ disabled }) => !disabled && css`
    :hover {
      ${Box} {
        transform: scale(1.05);
        box-shadow: 0 0 20px 5px ${({ color }) => transparentize(0.8, color)};
      }
    }

    :active {
      ${Box} {
        transform: scale(0.97);
        box-shadow: 0 0 20px 5px ${({ color }) => transparentize(0.8, color)};
      }
    }
  `}
`;

const Box = styled.div`
  box-sizing: initial; /* necessary for Kirby build target */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 266px;
  color: #fff;
  margin: 0 12px;
  font-weight: bold;
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  transition: transform 150ms ease, box-shadow 500ms ease;
`;

const Text = styled.span`
  font-size: 1.2rem;
`;

const Value = styled.span`
  font-size: 3rem;
`;

const InvoiceDetailBox = ({ text, value, color = '#53be79', disabled = (value === 0), onClick, arrowIdleAnimOffset, ...props }) => {

  if (disabled) {
    color = '#9798a4';
  }

  return (
    <Wrapper onClick={!disabled ? onClick : null} color={color} disabled={disabled} {...props}>
      <Box color={color}>
        <Value><CountUp start={0} end={value} duration={4} /></Value>
        <Text>{text}</Text>
      </Box>
    </Wrapper>
  );
};

export default React.memo(InvoiceDetailBox);
