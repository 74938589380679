import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  font-weight: normal;
  color: #0c2a7a;
  font-size: 3rem;
  text-transform: uppercase;
  margin: 0;
`;

export default React.memo(Title);
