import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Title from '../components/Title';
import Tabs from '../components/Tabs';
import ExtractionTable from '../components/ExtractionTable';
import ImageHighlighter from '../components/ImageHighlighter';
import InvoiceDetailBox from '../components/InvoiceDetailBox';
import { KeyValuePropType, scrollToElem } from '../utils';

const colorByGroup = {
  general: '#030436',
  payment: '#ff0050',
  items: '#0c2a7a',
  contact: '#4c69c5'
};
Object.freeze(colorByGroup);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  width: 100%;
`;

const BoxesWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
`;

const HighlighterWrapper = styled.div`
  box-sizing: initial; /* necessary for Kirby build target */
  position: relative;
  display: inline-block;
  width: 470px;
  border: 35px solid #0c2a7a;
`;

const HighligherControllersWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

const PaginationButton = styled.button`
  box-sizing: initial; /* necessary for Kirby build target */
  background: none;
  border: 0;
  color: #fff;
  cursor: pointer;
  height: 100%;
  padding: 0 7px;
  transition: opacity 200ms ease-out;

  :hover {
    opacity: 0.6;
  }

  :focus {
    outline: 0;
  }
`;

const PageDisplay = styled.span`
  font-size: 0.8em;
  margin: 0 5px;
`;

const IconChevron = ({ flipped }) => (
  <svg height="6px" viewBox="0 0 47 31">
    <path fill="#fff" transform={flipped ? 'rotate(180 23.5 15.5)' : ''} d="M43.556 0c-.81 0-1.756.31-2.431 1.088L26.809 17.564c-1.391 1.754-2.538 2.631-3.44 2.631-.903 0-2.007-.877-3.313-2.63L5.875 1.087C5.2.311 4.389 0 3.444 0c-.945 0-1.756.31-2.431 1.088-1.35 1.554-1.35 4.041 0 5.44L21 29.533c.135.155.406.31.54.466.946.778 2.162.778 3.107.311.405-.155.81-.466 1.08-.777l20.26-23.005c1.35-1.554 1.35-4.041 0-5.44C45.311.311 44.5 0 43.555 0z" />
  </svg>
);

class ExtractionDetailsContainer extends Component {
  static propTypes = {
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    model: PropTypes.shape({
      name: PropTypes.string,
      version: PropTypes.string
    }),
    results: PropTypes.shape({
      general: PropTypes.arrayOf(KeyValuePropType),
      sender: PropTypes.arrayOf(KeyValuePropType),
      recipient: PropTypes.arrayOf(KeyValuePropType),
      items: PropTypes.arrayOf(KeyValuePropType)
    }),
    taxRisksAmount:PropTypes.number
  }

  static defaultProps = {
    results: null,
    model: null,
    imageUrl: null
  }

  state = {
    region: null,
    imageIndex: 0,
    selectedTab: 'general'
  }

  handleHover = row => {
    this.setState({
      region: row.region,
      imageIndex: row.region.page
    });
  }

  handleLeave = () => {
    this.setState({
      region: null
    });
  }

  handleTabChange = i => {
    this.setState({
      selectedTab: i
    });
  }

  handleImageIndexChange = changeBy => {
    this.setState(prevState => ({
      imageIndex: changeBy >= 0 ? (prevState.imageIndex + changeBy) % this.props.imageUrls.length : (prevState.imageIndex > 0 ? prevState.imageIndex + changeBy : this.props.imageUrls.length - 1)
    }));
  }

  renderHighlighterControls = () => {
    if (this.props.imageUrls.length === 1) return null;

    return (
      <HighligherControllersWrapper>
        <PaginationButton onClick={this.handleImageIndexChange.bind(this, -1)}><IconChevron flipped /></PaginationButton>
        <PageDisplay>{this.state.imageIndex + 1} / {this.props.imageUrls.length}</PageDisplay>
        <PaginationButton onClick={this.handleImageIndexChange.bind(this, 1)}><IconChevron /></PaginationButton>
      </HighligherControllersWrapper>
    );
  }

  handleExtractionDetailsClick = () => scrollToElem('extraction-details', 700);
  handleAccountingClick = () => scrollToElem('accounting', 700);
  handleTaxRisksClick = () => scrollToElem('vat-compliance', 700);

  render() {
    return (
      <Wrapper>
        <Title id="extraction">{LANG.dataExtraction.title}</Title>
        <BoxesWrapper>
          <InvoiceDetailBox onClick={this.handleExtractionDetailsClick} text={LANG.dataExtraction.detailsDataPoints} value={this.props.resultsCount} color="#3149a8" />
          <InvoiceDetailBox onClick={this.handleAccountingClick} text={LANG.dataExtraction.detailsAccountingRecords} value={this.props.predictionsCount} color="#ff0050" />
          {this.props.taxRisksAmount > -1 && <InvoiceDetailBox onClick={this.handleTaxRisksClick} disabled={false} text={LANG.dataExtraction.taxRisks} value={this.props.taxRisksAmount} color={this.props.taxRisksAmount === 0 ? '#74b680' : '#030436'} />}
        </BoxesWrapper>
        <SubWrapper id="extraction-details">
          <Tabs onChange={this.handleTabChange} selectedTab={this.state.selectedTab}>
            {/* General group always has to be there */}
            <Tabs.Tab id="general" title={LANG.dataExtraction.table.groupName.general} color={colorByGroup.general}>
              <ExtractionTable color={colorByGroup.general} onHover={this.handleHover} onLeave={this.handleLeave} modelName={this.props.model.name} rows={this.props.results.general} />
            </Tabs.Tab>
            {Object.keys(this.props.results).map(group => (
              group !== 'general' && this.props.results[group] && this.props.results[group].length > 0 ? (
                <Tabs.Tab key={group} id={group} title={LANG.dataExtraction.table.groupName[group] || group} color={colorByGroup[group] || colorByGroup.general}>
                  <ExtractionTable color={colorByGroup[group] || colorByGroup.general} onHover={this.handleHover} onLeave={this.handleLeave} modelName={this.props.model.name} rows={this.props.results[group]} />
                </Tabs.Tab>
              ) : null
            ))}
          </Tabs>
          <HighlighterWrapper>
            <ImageHighlighter imageUrl={this.props.imageUrls[this.state.imageIndex]} highlightedRegion={this.state.region} highlightColor={colorByGroup[this.state.selectedTab] || colorByGroup.general} />
            {this.renderHighlighterControls()}
          </HighlighterWrapper>
        </SubWrapper>
      </Wrapper>
    );
  }
}

export default ExtractionDetailsContainer;
