import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  box-sizing: initial; /* necessary for Kirby build target */
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 30px;
  border: 4px solid ${({ color }) => color};
  color: ${({ color }) => color};
  padding: 20px;
  padding-bottom: 0;
  justify-content: space-around;
`;

const Title = styled.span`
  flex: 1;
  font-weight: bold;
  font-size: 1.4rem;
  padding-top: 10px;
  cursor: default;
`;

const ValueWrapper = styled.span`
  flex: 1;
  display: flex;
  justify-content: ${({ empty }) => empty ? 'flex-start' : 'center'};
  padding-left: ${({ empty }) => empty ? '40px' : '0'};
`;

const Value = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  word-break: break-all; /* Fallback for Edge */
  word-break: break-word;
`;

const AccountingDetailBox = ({ title, value, color = '#53be79' }) => (
  <Wrapper color={color}>
    <Title>{title}</Title>
    <ValueWrapper empty={!value}>
      <Value>{value || '—'}</Value>
    </ValueWrapper>
  </Wrapper>
);

export default React.memo(AccountingDetailBox);
