import React from 'react';
import styled from 'styled-components';
import Particles from 'react-particles-js';

import params from '../particles.json';

const StyledParticles = styled(Particles).attrs(() => ({ params }))`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export default React.memo(StyledParticles);
